<template>
    <div>
    <ErrorAlert :msg="errorMgs" :visible="ShowError" />   
 <b-container  class="p-1" >
     
     
    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 

        <img class="img-fluid" :src="$store.state.backendUrl+post.image" alt="">
      </b-col>
      <b-col cols="12" lg="12" xl="12" class="mt-5" > 

        <h1>{{post.name}}</h1>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" class="text-right"> 
         <p> <b-icon icon="calendar" scale="1.25" shift-v="1.25"  aria-hidden="true"> </b-icon> {{$t('Start Date')}} : {{post.start_date  | moment}}</p>
         <p> <b-icon icon="calendar" scale="1.25" shift-v="1.25"  aria-hidden="true"> </b-icon> {{$t('End Date')}} : {{post.end_date  | moment}}</p>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
       <div v-html="post.description" class="text-right"></div>

      </b-col>
    </b-row>

    

    <b-row class="mt-5">
      <b-col lg="6" class="pb-2"><b-button @click="routerTo('videos')" size="lg" variant="primary"> <b-icon icon="tv" scale="1" shift-v="1"  aria-hidden="true"></b-icon> شاهد فيديوهات المواهب المشتركة </b-button></b-col>
      <b-col lg="6" class="pb-2"><b-button v-if="canSub" @click="routerTo('subs')" size="lg" variant="primary"> <b-icon icon="camera-reels-fill" scale="1" shift-v="1"  aria-hidden="true"></b-icon> اشترك فى المسابقة</b-button></b-col>
   </b-row>
<br>
<br>
<br>
<h2>الاعلي تصويتا</h2>
<br>
<br>
   <b-row >
    
       <b-col  cols="12" lg="4" xl="4" md="6"  v-for="(postX,itemObjKey) in topThree" :key="'competitorX'+ postX.id" >
         <h4>{{RankingNames[itemObjKey]}} </h4>
        <b-card 
            :title="postX.name"
            :img-src="'https://img.youtube.com/vi/'+getIdFromUTuberl(postX.url)+'/0.jpg'"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2"
        >
      

        <b-button :to="{ name: 'Competitor_show',params: { id: postX.id }  }" variant="primary">المزيد</b-button>
        </b-card>


     
       </b-col>
   </b-row>








   <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
               
             <b-nav class="shareBTNS" >
                 <b-nav-item> شارك : </b-nav-item>
            <b-nav-item  :href="'https://www.facebook.com/sharer/sharer.php?u='+$store.state.baseUrl+'competition/'+post.slug" target="_blank"><b-icon icon="facebook" scale="1" shift-v="1"  aria-hidden="true"></b-icon></b-nav-item>
            <b-nav-item  :href="'https://twitter.com/intent/tweet?text='+post.name+' '+$store.state.baseUrl+'competition/'+post.slug" target="_blank"><b-icon icon="twitter" scale="1" shift-v="1" aria-hidden="true"></b-icon></b-nav-item>
     
            </b-nav>
      </b-col>
    </b-row>


</b-container>

    </div>
</template>

<script>
import axios from 'axios'
//import PostsSkeleton from '@/components/skeletons/PostsSkeleton.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import moment from "moment";
import {getIdFromUrl } from 'vue-youtube'
  export default {
      name:'Competitions',
    data() {
      return {
        moment: function () {
        return moment();
        },
        showContent: false,
        path:this.$store.state.backendUrl+"api/ar/competitions/"+this.$route.params.slug,
        post:[],
        $topThree:[],
        RankingNames:['المركز الاول','المركز الثانى','المركز الثالث'],
        videoLink:"",
        subLink:"",
        errorMgs:'',
        ShowError: false,
        canSub: false,
        indexM:-1
      }
    },
    components:{
     ErrorAlert
    }
    ,
    methods: {
       increaseindexM(){
          this.indexM++;
       return this.RankingNames[this.indexM];
      
       },



       getIdFromUTuberl(url){
       return  getIdFromUrl(url)
      },
      routerTo(link){
        if(link == 'videos'){ this.$router.push(this.videoLink)}
        if(link == 'subs'){this.$router.push(this.subLink)}

      },
  
           getCreateData(){
           this.showContent =false;
           axios.get(this.path, {params:this.form,
            headers: {
          // 'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
              console.log(response);
              this.post = response.data.success.post;
              this.topThree = response.data.success.topThree;
              this.canSub = response.data.success.canSub;
               this.showContent =true;
               if(!this.$store.state.token){this.subLink ="/login"}else
              { this.subLink ='/competitors_add/'+this.post.id; }
              this.videoLink ='/competitors/'+this.post.slug; 
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } ,
 
    },
    filters: {
      moment: function (date) {
        return moment(date).format('DD-MM-YYYY');
      }
      },
     /*********************** */
    mounted(){
     
     this.getCreateData();
    }
  }
</script>